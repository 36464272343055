import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import variables from '_config/css-variables'
import { rawToJSON } from '_utils/functions'
import { useWindowSize } from '_hooks'
import { MovePortfolio } from '_templates'

const MoveCaseStudy = ({ data }) => {
  const content = data.contentfulMoveCaseStudy
  const [width] = useWindowSize()
  const isMobile = width <= 768

  return (
    <MovePortfolio
      navbarColorOverride={variables['move-primary']}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageThumbnailUrl={content.pageThumbnail.file.url}
      heroBackgroundTexture={content.heroBackgroundTexture.file.url}
      logo={content.moveLogo.file.url}
      logoDescription={content.moveLogo.description}
      heroDescription={content.heroDescription.internal.content}
      heroVectary={content.heroVectary.file.url}
      heroVectaryDescription={content.heroVectary.description}
      challengeTitle={content.challengeSectionTitle}
      challengeDescription={content.challengeSectionText.internal.content}
      backgroundChallenge={content.challengeSectionBackground.file.url}
      iconDrivers={content.infoSectionIconDrivers.file.url}
      altIconDrivers={content.infoSectionIconDrivers.description}
      titleDrivers={content.infoTitleDrivers}
      descriptionDrivers={rawToJSON(content.infoSectionTextDrivers.raw)}
      iconCharging={content.infoSectionIconCharging.file.url}
      altIconCharging={content.infoSectionIconCharging.description}
      titleCharging={content.infoTitleCharging}
      descriptionCharging={rawToJSON(content.infoSectionTextCharging.raw)}
      missionBackground={content.missionBackground.file.url}
      missionIcon={content.missionIcon.file.url}
      missionIconAlt={content.missionIcon.description}
      missionTitle={content.missionTitle}
      missionText={content.missionText.internal.content}
      differentialBackground={content.differentialBackground.file.url}
      differentialTitle={content.differentialTitle}
      differentialDescription={content.differencialDescription.internal.content}
      differentialCardCode={
        isMobile
          ? content.differentialCardCodeMobile.file.url
          : content.differentialCardCode.file.url
      }
      differentialCardCodeAlt={content.differentialCardCode.description}
      reportingTitle={content.reportingTitle}
      reportingDescription={content.reportingDescription.internal.content}
      reportingImageDashboard={content.reportingImageDashboard.file.url}
      reportingImageDashboardAlt={content.reportingImageDashboard.description}
      reportingMobile={content.reportingMobile.file.url}
      reportingMobileAlt={content.reportingMobile.description}
      iconAES={content.resultsSectionIconAesInvestment.file.url}
      iconAESAlt={content.resultsSectionIconAesInvestment.description}
      resultsTitle={content.resultsTitle}
      resultTitle1={content.resultTextTitle1}
      resultDescription1A={content.resultDescription1A}
      resultDescription1B={content.resultDescription1B}
      resultDescription1C={content.resultDescription1C}
      iconExperience={content.resultsSectionIconBetterExperience.file.url}
      iconExperienceAlt={content.resultsSectionIconBetterExperience.description}
      resultTitle2={content.resultTextTitle2}
      resultDescription2A={content.resultDescription2A}
      resultDescription2B={content.resultDescription2B}
      proudCases={content.proudCases}
    />
  )
}
export const query = graphql`
  query contentfulMoveCaseStudy($locale: String) {
    contentfulMoveCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        description
        file {
          url
        }
      }
      moveLogo {
        description
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      heroBackgroundTexture {
        description
        file {
          url
        }
      }
      heroVectary {
        description
        file {
          url
        }
      }
      challengeSectionBackground {
        description
        file {
          url
        }
      }
      challengeSectionTitle
      challengeSectionText {
        internal {
          content
        }
      }
      infoSectionIconDrivers {
        description
        file {
          url
        }
      }
      infoTitleDrivers
      infoSectionTextDrivers {
        raw
      }
      infoSectionIconCharging {
        description
        file {
          url
        }
      }
      infoTitleCharging
      infoSectionTextCharging {
        raw
      }
      missionBackground {
        description
        file {
          url
        }
      }
      missionIcon {
        description
        file {
          url
        }
      }
      missionTitle
      missionText {
        internal {
          content
        }
      }
      differentialBackground {
        description
        file {
          url
        }
      }
      differentialTitle
      differencialDescription {
        internal {
          content
        }
      }
      differentialCardCode {
        description
        file {
          url
        }
      }
      differentialCardCodeMobile {
        description
        file {
          url
        }
      }
      reportingTitle
      reportingDescription {
        internal {
          content
        }
      }
      reportingImageDashboard {
        description
        file {
          url
        }
      }
      reportingMobile {
        description
        file {
          url
        }
      }
      resultsTitle
      resultTextTitle1
      resultDescription1A
      resultDescription1B
      resultDescription1C
      resultsSectionIconAesInvestment {
        description
        file {
          url
        }
      }
      resultTextTitle2
      resultDescription2A
      resultDescription2B
      resultsSectionIconBetterExperience {
        description
        file {
          url
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

MoveCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default MoveCaseStudy
